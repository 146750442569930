import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Header from './adminHeader';
import 'react-quill/dist/quill.snow.css'; // Import React Quill CSS
import ReactQuill from 'react-quill';
import apiService from '../core/service/v1';
import { postMethod } from '../core/service/common.api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function BlogUpload() {
    const [inputValues, setinputValues] = useState(
        {
            metaTitle: '',
            metaDescription: '',
            metaSlug: '',
            blogCategory: '',
            keywords: '',
            uploadImage: '',
            cmsEditor: '',
            tags: ''
        }
    );

    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        values: inputValues
    });
    const [cmsContent, setCmsContent] = useState('');
    const [uploadImage, setuploadImage] = useState('');
    const [uploadBtn, setuploadBtn] = useState(false);
    const navigate = useNavigate()
    var url
    useEffect(() => {
        url = window.location.href.split("?")[1]
        if (url != undefined) {
            getblog()
            setuploadBtn(true)
        }

    }, [0])
    const getblog = async () => {
        var data = {
            apiUrl: apiService.getblogdetail,
            payload: { _id: url },
        };
        var resp = await postMethod(data)
        if (resp.status == true) {
            setinputValues(resp.data)
            setCmsContent(resp.data.cmsEditor)
            setuploadImage(resp.data.uploadImage)
        }
    }

    const croppedImageUpload = async (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;

            image.onload = () => {
                if (image.width === 1260 && image.height === 519) {
                    const fileExtension = file.name.split(".").at(-1);
                    const fileSize = file.size;
                    if (
                        fileExtension != "jpeg" &&
                        fileExtension != "png" &&
                        fileExtension != "jpg"
                    ) {
                        toast.error("File does not support. only upload .jpeg or .png or jpg");
                    } else if (fileSize > 10000000) {
                        toast.error("Please upload a file smaller than 100 MB");
                    } else {
                        const data = new FormData();
                        data.append("file", file);
                        data.append("upload_preset", "yckbfakp");
                        data.append("cloud_name", "dapljmrfe");
                        fetch("https://api.cloudinary.com/v1_1/dapljmrfe/auto/upload", {
                            method: "post",
                            body: data,
                        })
                            .then((resp) => resp.json())
                            .then((data) => {
                                setuploadImage(data.secure_url);
                            })
                            .catch((err) => {
                            });
                    }
                } else {
                    toast.error('Image must be 1260x519 pixels.')
                }
            };
        };
    };

    const onSubmit = async (data) => {
        data.cmsEditor = cmsContent; // Add cmsContent to form data
        data.uploadImage = uploadImage == "" ? "" : uploadImage
        var data = {
            apiUrl: apiService.addblogdetails,
            payload: data,
        };
        var resp = await postMethod(data)
        if (resp.status == true) {
            toast.success(resp.Message)
            navigate('/admin/blogs')
        } else {
            toast.error(resp.Message)
        }
    };

    // Register the custom editor with react-hook-form
    React.useEffect(() => {
        register('cmsEditor', { required: true });
    }, [register]);

    return (
        <>
            <Header />
            <div className="container container3">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-group">
                        <label htmlFor="metaTitle">Meta Title</label>
                        <input
                            id="metaTitle"
                            className="form-control"
                            {...register('metaTitle', { required: true })}
                        />
                        {errors.metaTitle && <span className="error">Meta Title is required</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="metaDescription">Meta Description</label>
                        <textarea
                            id="metaDescription"
                            className="form-control"
                            {...register('metaDescription', { required: true })}
                        />
                        {errors.metaDescription && <span className="error">Meta Description is required</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="metaSlug">Meta Slug</label>
                        <input
                            id="metaSlug"
                            className="form-control"
                            {...register('metaSlug', { required: true })}
                        />
                        {errors.metaSlug && <span className="error">Meta Slug is required</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="blogCategory">Blog Category</label>
                        <select id="blogCategory"
                            className="form-control"
                            {...register('blogCategory', { required: true })}>
                            <option>AI Legal Documents</option>
                            <option>Smart Legal Contracts</option>
                            <option>Digital Signature</option>
                            <option>Uploading Blockchain</option>
                            <option>Global Information AI</option>
                            <option>Mediation</option>
                            <option>Arbitration</option>
                            <option>Marketplace for Lawyers</option>
                            <option>AI</option>
                            <option>Law</option>
                        </select>
                        {/* <input/> */}
                        {errors.blogCategory && <span className="error">Category is required</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="keywords">Keywords</label>
                        <input
                            id="keywords"
                            className="form-control"
                            {...register('keywords', { required: true })}
                        />
                        {errors.keywords && <span className="error">Keywords are required</span>}
                    </div>

                        <div
                            class="group relative flex max-w-xxl flex-col  items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100  py-20 mb-5 px-5 text-center h-100">
                            <div class="relative z-10 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                    class="mb-4 inline-block fill-jacarta-500" viewBox="0 0 24 24"
                                    fill="currentColor">
                                    <path
                                        d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
                                </svg>
                                <p class="mx-auto max-w-xs text-l dark:text-jacarta-300">Click Here
                                    to Upload Blog Image</p>
                            </div>
                            <div
                                class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0 dark:bg-jacarta-600"></div>
                            <input type="file" accept="image/*" id="uploadImage" ref={fileInputRef}  onChange={(e) => {
                                croppedImageUpload(e.target.files[0]);
                            }}
                                class="absolute inset-0 z-20 cursor-pointer opacity-0" />
                        </div> 
                       
                    {/* <div className="form-group">
                        <label htmlFor="uploadImage">Upload Image</label>
                        <input
                            type="file"
                            id="uploadImage"
                            onChange={(e) => {
                                croppedImageUpload(e.target.files[0]);
                            }}
                            className="form-control"
                        />
                        {errors.uploadImage && <span className="error">{errors.uploadImage}</span>}
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="cmsEditor">CMS Editor</label>
                        <ReactQuill
                            value={cmsContent}
                            onChange={setCmsContent}
                            onBlur={() => setValue('cmsEditor', cmsContent)}
                            className="form-control"
                        />
                        {errors.cmsEditor && <span className="error">CMS content is required</span>}
                    </div>
                    {uploadBtn == false ? <button type="submit" className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">Submit</button> : <button className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">Update</button>}
                    <button></button>

                </form>
            </div>
        </>
    );
}

export default BlogUpload;
