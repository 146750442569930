import React, { useState, useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { toast } from 'react-toastify';
import Header from './Header';
import { getWeb3, keys, } from "../core/helper/getWeb3";
import { create } from 'ipfs-http-client';
import {
  LAWBLOCKS_CONTRACT,
} from "../core/helper/constant";
import LawContract from "../core/helper/lawblocks.json";
import { postMethod } from '../core/service/common.api';
import apiService from '../core/service/v1';
import { Buffer } from "buffer";

const { ethers } = require('ethers');
const { JsonRpcProvider, Wallet } = ethers;

// const apiurl = "http://localhost:5001/"
const apiurl = "https://marketapi.lawblocks.io/"

const fonts = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];

const SignaturePage = () => {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [signatureMode, setSignatureMode] = useState(null);
  const [signature, setSignature] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const [typedText, setTypedText] = useState('');
  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [signedPdfUrl, setSignedPdfUrl] = useState('');
  const [signedPdfs, setSignedPdfs] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [email, setEmail] = useState('');
  const [_id, set_id] = useState('');
  const [Choosefilename, setChoosefilename] = useState('');
  const [EmailStatus, setEmailStatus] = useState('');
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [btnOn, setbtnOn] = useState(false);
  const [signstatus, setsignstatus] = useState(false);
  const signatureRef = useRef();
  const canvasRef = useRef(null);
  const [resfile, setresfile] = useState({});
  const [sharedPdf, setsharedPdf] = useState([]);

  var web3 = getWeb3();

  useEffect(() => {
    fetchSignedPdfs();
    getSharedpdf();
  }, []);

  const fetchSignedPdfs = async () => {
    try {
      const response = await axios.get(apiurl + 'api/signed-pdfs');
      setSignedPdfs(response.data.data);
    } catch (error) {
      console.error('Error fetching signed PDFs:', error);
    }
  };
  const getSharedpdf = async () => {
    try {
      const response = await axios.get(apiurl + 'api/getsharedPdf');
      setsharedPdf(response.data.data);
    } catch (error) {
      console.error('Error fetching signed PDFs:', error);
    }
  };
  const getSignatures = async (file, type) => {
    try {

      if (type == "download") {
        var name = file.fileName
        const response = await axios.post(apiurl + 'api/get-signatures', { name }, {
          responseType: 'arraybuffer',
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      if (type == 'sign') {
        setbtnOn(true)
        setsignstatus(true)
      }
      set_id(file._id)
      setChoosefilename(file.fileName)
      setresfile(file.file)
      var name = file.fileName
      const response = await axios.post(apiurl + 'api/get-signatures', { name }, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setFile(url);
    } catch (error) {
      console.error('Error fetching signed PDFs:', error);
    }
  };

  const onFileChange = (event) => {
    if (event.target.files[0].name.split('.')[1] == 'pdf') {
      setFile(event.target.files[0]);
      setSignedPdfUrl('');
    } else {
      toast.error("Please upload a PDF file")
    }

  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSignatureMode = (mode) => {
    setSignatureMode(mode);
  };

  const handleDrawSignature = () => {
    if (signatureRef.current) {
      const dataURL = signatureRef.current.toDataURL();
      setSignature(dataURL);
    }
  };

  const handleUploadSignature = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setSignature(e.target.result);
      setSignatureMode(null);
    };
    reader.readAsDataURL(file);
  };

  const handleTypeSignature = (event) => {
    setTypedText(event.target.value);
  };

  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };

  useEffect(() => {
    if (typedText) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = `30px ${selectedFont}`;
      ctx.fillStyle = 'black';
      ctx.fillText(typedText, 10, 50);
      setSignature(canvas.toDataURL());
    }
  }, [typedText, selectedFont]);

  const handlePlaceSignature = (event) => {
    setSignatureMode(null)
    if (signature) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const newSignature = { image: signature, x, y };
      setSignatures([...signatures, newSignature]);
      setSignature(null);
      setTypedText('');
    }
  };
  const [loaddone, setloaddone] = useState(false)
  const signDone = async () => {

    console.log(signatureMode)
    if (
      signatures.length == 0 && signatureMode != null
    ) {
      toast.error("please place your signature")
      return
    }
    if (file && signatures.length > 0) {
      const formData = new FormData();
      formData.append('file', resfile);
      formData.append('fileName', Choosefilename);
      formData.append('pageNumber', pageNumber);
      formData.append('id', _id);
      formData.append('signatures', JSON.stringify(signatures));
      setloaddone(true)
      const response = await axios.post(apiurl + 'api/Signedpdf', formData);
      setloaddone(false)
      if (response.data.status == true) {
        toast.success(response.data.Message)
        setsignstatus(false)
        setbtnOn(false)
        setFile(null)
        setSignedPdfUrl('')
        setSignatures([])
        getSharedpdf()
      } else {
        toast.error(response.data.Message)
      }
    }

  }

  const handleSaveSignedPDF = async () => {
    if (!email) {
      toast.error('Enter Email');
      return;
    }
    if (
      signatures.length == 0 && signatureMode != null
    ) {
      toast.error("please place your signature")
      return
    }
    if (file) {
      const formData = new FormData();
      formData.append('pdf', file);
      formData.append('file', resfile);
      formData.append('pageNumber', pageNumber);
      formData.append('fileName', file.name == undefined ? Choosefilename : file.name);
      formData.append('email', email);
      formData.append('id', _id)
      formData.append('signatures', JSON.stringify(signatures));

      try {
        setLoadingBtn(true);
        const response = await axios.post(apiurl + 'api/save-signed-pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data.status == true) {

          fetchSignedPdfs();
          setSignedPdfUrl(`/signed_pdfs/signed_${file.name}`);
          // setEmailStatus(false);
          // setbtnOn(false)
          // setFile(null)
          setEmail('')
          setSignedPdfUrl('')
          setSignatures([])
          toast.success(response.data.Message);
        } else {
          toast.error(response.data.Message);
        }
      } catch (error) {
        console.error('Error saving signed PDF:', error);
        toast.error('Error saving signed PDF');
      } finally {
        setLoadingBtn(false);
      }
    }
  };

  const pagefunction = (key, pageNumber) => {

    if (numPages > 0) {
      if (key == 'prev') {
        setPageNumber(pageNumber - 1)
      }
      if (key == 'next') {
        setPageNumber(pageNumber + 1)
      }
    }
  }

  const cancelFunction = () => {
    setsignstatus(false)
    setEmailStatus(false)
  }

  const [loading, setloading] = useState(false)
  

  const apiKey = '73e75cf7fb194db4869ff36e1084cd2d';
  const apiSecret = 'YDcoUylIkgQpxaGSYMOv6DWNZVqhZ+4Vt7EWetY5+p7BKW3UcU1/cg';
  const auth = 'Basic ' + Buffer.from(apiKey + ':' + apiSecret).toString('base64');
  const ipfs = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization: auth,
    },
  });
  const toXdc = (addrass) => {
    return 'xdc' + String(addrass).slice(2)
}
  const Upload_contact = async (file) => {

    try {
      toast('Wait few seconds Uploading...')
      if (!web3.currentProvider) {
        toast.error('Connection Error');
        setloading(false)
        return;
      }
      const key = await keys();
      // Ensure web3 instance is correctly connected
      const isListening = await web3.eth.net.isListening();
      if (!isListening) {
        setloading(false)
        toast.error('Web3 is not connected to the network');
        return;
      }

      // Get balance
      const address = web3.eth.accounts.wallet[0].address; // Replace with your wallet address
      let balance = await web3.eth.getBalance(address);
      balance = web3.utils.fromWei(balance, 'ether');

      // Get gas price
      const gasPrice = await web3.eth.getGasPrice();
      const getGas = Number(gasPrice);

      if (isNaN(getGas)) {
        setloading(false)
        throw new Error('Gas price is NaN');
      }
      const gasLimit = 2100000;

      var fee = getGas / 1000000000;
      var fee1 = fee / 1000000000;
      var fee2 = fee1 * 2100000;
      var limit = fee1 + 0.00007882225;
      if (balance <= limit) {
        setloading(false)
        toast.error('Insufficient balance');
        return;
      }
      const fileBuffer = new Blob([file.file.data], {
        type:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const result = await ipfs.add(fileBuffer)
      console.log(result, "=====result")
      const signature = web3.eth.accounts.sign(result.path, key);
      if (!signature) {
        setloading(false)
        toast.error('Failed to sign IPFS hash');
        return;
      }
      const ethersProvider = new JsonRpcProvider(
        "https://earpc.xinfin.network"
      );
      const ethersWallet = new ethers.Wallet(key, ethersProvider);
      const lawEthersInstance = new ethers.Contract(
        LAWBLOCKS_CONTRACT,
        LawContract,
        ethersWallet
      );

      var txConfig = {
        gasLimit: "2100000",
      };

      const uploadHash = await lawEthersInstance.uploadFile(
        result.path.toString(),
        file.fileName.toString(),
        signature.toString(),
        txConfig
      );
      const documentNo = await lawEthersInstance._contractIds();
      const Data = {
        fileName: file.fileName.toString(),
        txnHash: uploadHash.hash.toString(),
        ipfsHash: result.path.toString(),
        documentNo: documentNo.toString(),
        walletAddress: toXdc(address).toString(),
        storageType: "IPFS",
      };
      var data = {
        apiUrl: apiService.UploadFile,
        payload: Data,
      };
      var resp = await postMethod(data)
      if (resp.Status == true) {
        toast.success(resp.Message)
        setloading(false)
      } else {
        toast.error(resp.Message)
        setloading(false)
      }
    } catch (e) {
      toast.error(e.Message)
      setloading(false)
    }
  }

  return (
    <>
      <Header />
      <section className="section">
        <div className="container">
          <div className="chat"></div>
        </div>
      </section>
      <div className="signature-page">
        <div className="document-area">
          {!file && !signedPdfUrl && (
            <>
              <div style={{ "position": "relative" }} className='pdf_upload_box {'>
                <div
                  class="group relative flex max-w-xxl flex-col  items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100  py-20 mb-5 px-5 text-center h-100">
                  <div class="relative z-10 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                      class="mb-4 inline-block fill-jacarta-500" viewBox="0 0 24 24"
                      fill="currentColor">
                      <path
                        d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
                    </svg>
                    <p class="mx-auto max-w-xs text-l dark:text-jacarta-300">Click Here
                      to Upload Signature</p>
                  </div>
                  <div
                    class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0 dark:bg-jacarta-600"></div>
                  <input type="file" id="file-upload" onChange={onFileChange}
                    class="absolute inset-0 z-20 cursor-pointer opacity-0" />
                </div>
              </div>
            </>
          )}
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} onClick={handlePlaceSignature} className="pdf-page" />
          </Document>
          {file ?
            <div className='nextbutton'>
              {pageNumber == 1 ? <button className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'>Previous</button> :
                <button onClick={() => pagefunction('prev', pageNumber)} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'>Previous</button>}{" "}
              <button className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'>{pageNumber}</button>{" "}
              {numPages == pageNumber ? "" :
                <button onClick={() => pagefunction('next', pageNumber)} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'>Next</button>}
            </div> : ""}
          {/* )} */}
          {signatures.map((sig, index) => (
            <div key={index} className="placed-signature" style={{ left: sig.x, top: sig.y }}>
              <img src={sig.image} alt="Signature" />
            </div>
          ))}
        </div>
        <div className="signature-area">
          {file || signedPdfs.length > 0 || sharedPdf.length > 0 ?
            "" : <h1 className='heading_sign found_cls'>No Document found</h1>}
          {signedPdfs.length > 0 ?
            <>
              <h1 className='heading_sign'>Shared Documents</h1>

              <div className="table-cls table_sing_list">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>File Name</th>
                      <th>Signer Email</th>
                      <th>Status</th>
                      <th className='text-right'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {signedPdfs.map((pdf, i) => {
                      return (
                        <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{pdf.fileName}</td>
                        <td>{pdf.signer}</td>
                        <td>{pdf.status === 0 ? <p>&#10060; </p> : <p>&#9989;</p>}</td>
                        {pdf.status == 1 ?
                          <td className='text-right'><button onClick={() => getSignatures(pdf, 'view')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'><i class="fa fa-eye"></i></button>{" "}
                            <button onClick={() => getSignatures(pdf, 'download')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'><i class="fa fa-download" ></i></button>{" "}
                            <button onClick={() => Upload_contact(pdf)} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'> <i class="fa fa-upload" ></i></button> 
                          </td>
                          :
                          <td className='text-right'><button onClick={() => getSignatures(pdf, 'view')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'><i class="fa fa-eye"></i></button>
                          </td>}
                      </tr>
                      )                      
                    })}
                  </tbody>
                </table>
              </div>
            </>
            : ""}
          {sharedPdf.length > 0 ?
            <>

              <h1 className='heading_sign'>Requested Document</h1>

              <div className="table-cls table_sing_list">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>File Name</th>
                      <th>Owner</th>
                      <th className='text-right'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sharedPdf.map((pdf, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{pdf.fileName}</td>
                        <td>{pdf.DocOwn}</td>
                        <td className='text-right'>{pdf.status == 0 ? <><button onClick={() => getSignatures(pdf, 'view')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'><i class="fa fa-eye"></i></button>{" "}<button onClick={() => getSignatures(pdf, 'sign')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark' >Sign</button></> : <><button onClick={() => getSignatures(pdf, 'view')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'><i class="fa fa-eye"></i></button>{" "}
                          <button onClick={() => getSignatures(pdf, 'download')} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'><i class="fa fa-download" ></i></button>
                        </>}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </> : ""}
          {signstatus == true ?
            <div className="signature-options">
              <div className='row_floex'>
                <h2>Add Your Signature</h2>
                <div className='sing_optin'>
                  {btnOn == false ?
                    <button onClick={() => setEmailStatus(true) && setEmailStatus(false)}><i class="fa fa-share-alt"></i></button> : loaddone == true ? <button>Loading</button> : <button onClick={signDone}>Done</button>}
                  <button onClick={() => handleSignatureMode('draw')}><i class="ri-sketching"></i></button>
                  <button onClick={() => handleSignatureMode('upload')}><i class="ri-upload-2-fill"></i></button>
                  <button onClick={() => handleSignatureMode('type')}><i class="ri-text"></i></button>
                </div>
              </div>
            </div> : ""}
          {signatureMode === 'draw' && (
            <div className="signature-input">
              <SignatureCanvas ref={signatureRef} canvasProps={{ className: 'signature-canvas' }} />
              <button onClick={handleDrawSignature} className='inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark'>Add Sign</button>
            </div>
          )}
          {signatureMode === 'upload' && (
            <>
              <div style={{ "position": "relative" }}>
                <div
                  class="group relative flex max-w-xxl flex-col  items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100  py-20 mb-5 px-5 text-center">
                  <div class="relative z-10 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                      class="mb-4 inline-block fill-jacarta-500" viewBox="0 0 24 24"
                      fill="currentColor">
                      <path
                        d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
                    </svg>
                    <p class="mx-auto max-w-xs text-l dark:text-jacarta-300">Click Here
                      to Upload Signature</p>
                  </div>
                  <div
                    class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0 dark:bg-jacarta-600"></div>
                  <input type="file" id="file-upload" onChange={handleUploadSignature} accept="image/*"
                    class="absolute inset-0 z-20 cursor-pointer opacity-0" />
                </div>
              </div>
              {/* <div className="signature-input">
                  <input type="file" onChange={handleUploadSignature} accept="image/*" />
                </div> */}
            </>

          )}
          {signatureMode === 'type' && (
            <div className="signature-input text_input">
              <input type="text" onChange={handleTypeSignature} placeholder="Type your name" value={typedText} />
              <select onChange={handleFontChange} value={selectedFont}>
                {fonts.map((font) => (
                  <option key={font} value={font}>
                    {font}
                  </option>
                ))}
              </select>
            </div>
          )}
          {signature && (
            <div className="signature-preview">
              <img src={signature} alt="Signature Preview" />
              <p>Click on the document to place your signature</p>
            </div>
          )}
          <canvas ref={canvasRef} style={{ display: 'none' }} width={500} height={100}></canvas>
          {file && signstatus == false && EmailStatus == false &&
            <div className="signature-options flex-center text_input">
              <button onClick={() => setsignstatus(true)}>Sign</button>
              <button onClick={() => setEmailStatus(true)}>Share Pdf</button>
            </div>
          }
          {EmailStatus &&
            <div className="signature-options flex-center text_input">
              <button onClick={cancelFunction}>Close</button>
              {loadingBtn ? <button disabled>Loading..</button> : <button onClick={handleSaveSignedPDF}>Share</button>}
            </div>
          }
          {/* {file  && (
            <div className="signature-options flex-center text_input">
              {EmailStatus == true ? "" : <button onClick={cancelFunction}>Cancel</button>}
              {btnOn == true ? loaddone == true ? <button>Loading</button> : <button onClick={signDone}>Done</button>
                : EmailStatus == false ?
                  <button onClick={() => setEmailStatus(true)}>Share PDF</button> : loadingBtn ? <button disabled>Loading..</button> : <button onClick={handleSaveSignedPDF}>Share</button>}
            </div>
          )} */}
          {EmailStatus && (
            <div className="signature-input text_input">
              <br />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter signer Email' />
              <br />
            </div>
          )}

        </div>
      </div>

    </>
  );
};

export default SignaturePage;
