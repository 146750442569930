import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import useStateRef from "react-usestateref";
import Headers from "../../pages/Header";


function Home() {
  // const apiurl = "http://localhost:5001/";
  const apiurl = "https://marketapi.lawblocks.io/"
  const navigate = useNavigate();
  const [File, setFile] = useState("");
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [checked, setchecked, checkedref] = useStateRef(false);

  const onFileChange = (event) => {
    if (event.target.files[0].name.split(".")[1] === "pdf") {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = () => {
        localStorage.setItem("pdfData", reader.result); // Store base64 PDF data
      };
          reader.readAsDataURL(event.target.files[0]);
    } else {
      toast.error("Please upload a PDF file");
    }
  };
  const [userDetails, setuserDetails] = useState({})

  useEffect(() => {
    const getuser = async () => {
      const response = await axios.get(apiurl + 'api/getuser');
      if (response.data.status == true) {
        setuserDetails(response.data.data)
      } else {
        setuserDetails(response.data.data)
      }
    }
    getuser()
  }, [])

  const checkbox = (e) => {
    setchecked(e.target.checked)
  }

  const nextAction = async (value) => {
    if (value == "/digital_sign" && File) {
      const formData = new FormData();
      formData.append("pdf", File);
      formData.append("file", localStorage.getItem("pdfData"));
      formData.append("email", userDetails.email);
      formData.append("name", userDetails.fullName);

      const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.status === true) {
        navigate(value + '?' + response.data.id)
      }
      return
    } else if (value === "/digitalDash") {
      navigate(value);
    } else {
      toast.error('Please select file')
    }
  };

  const addSigner = async () => {
    if (File) {
      if (!Email && !name) {
        toast.error("Email and Name are required");
        return;
      }
      const formData = new FormData();
      formData.append("pdf", File);
      formData.append("file", localStorage.getItem("pdfData"));
      formData.append("email", Email);
      formData.append("name", name);

      const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === true) {
        setEmail("");
        setname("");
        toast.success(response.data.Message);
      } else {
        toast.error(response.data.Message);
      }
    } else {
      toast.error("Choose file");
    }
  };

  return (
    <>
      <Headers />
      <section className="py-10 pt-20">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 p-4">
              <div className="bg-[#0D0B35] min-h-screen flex items-start justify-center w-full flex-col justify-start items-start">
                <h2 className="text-left text-white text-3xl font-semibold mb-8">
                  Add Documents
                </h2>
                <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                  <label
                    htmlFor="file-upload"
                    className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center upload_section_code"
                  >
                    {File == "" ?
                      <>
                        <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                        <span className="text-white text-lg font-medium">
                          Upload Document
                        </span></>
                      : <i class="fa fa-file-pdf-o" style={{ fontSize: "200px" }} aria-hidden="true"></i>}
                    <input id="file-upload" type="file" className="hidden" onChange={onFileChange} />
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <h2 className="text-left text-white text-3xl font-semibold mb-8">
                Add Recipients
              </h2>
              <div className="min-h-screen flex items-center justify-center">
                <div className="dark:bg-jacarta-700 w-full rounded-xl p-8 py-10 space-y-6">
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      id="only-signer"
                      onChange={checkbox}
                      className="h-5 w-5 text-[#6c41d6] bg-transparent border-2 border-[#6c41d6] rounded focus:ring-0"
                    />
                    <label
                      htmlFor="only-signer"
                      className="text-white text-lg font-medium"
                    >
                      I am the only signer
                    </label>
                  </div>
                  {checkedref.current == false ?
                    <>
                      <div className="mb-6">
                        <label
                          htmlFor="item-name"
                          className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                        >
                          Name<span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          id="item-name"
                          name="name"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                          className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                          placeholder="Enter Name"
                          required
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="item-email"
                          className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                        >
                          Email<span className="text-red">*</span>
                        </label>
                        <input
                          type="email"
                          id="item-email"
                          name="email"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                      <div className="form_betwine flex justify-end pt-2">
                        <Link
                          className="inline-block rounded bg-accent py-2 px-5 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          onClick={addSigner}
                        >
                          <i className="ri-user-add-line text-lg"></i>
                          <span>Add recipients</span>
                        </Link>
                      </div>
                    </> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4">
        <div className="container-lg mx-auto flex justify-end">
          <button
            className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            onClick={() => nextAction("/digitalDash")}
          >
            Back
          </button>
          <button
            className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            onClick={() => nextAction("/digital_sign")}
          >
            Next
          </button>
        </div>
      </footer>
    </>
  );
}

export default Home;
